import { trackCustomEvent } from "gatsby-plugin-google-analytics"

export const logEvent = (page, label) => {
  if (typeof window !== "undefined") {
    trackCustomEvent({
      category: label,
      action: "Click",
      label: page + " - " + label,
      value: 1,
    })
  }
}

//category: Onboarding
//action: Goal Click
//label: 'Simulate Plan'
