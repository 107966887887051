import React from "react"
import { Provider } from "../../utilities/context"
import Reset from "../../styles/reset"
import Theme from "../../styles/theme"
import Styles from "../../styles/shared"
import Cursor from "../shared/cursor"
import VideoPlayer from "../shared/video-player"
import GoalDetail from "../shared/goal-detail"

const Shell = props => {
  return (
    <Provider>
      <Theme>
        <Reset />
        <Styles />
        <Cursor />
        <VideoPlayer />
        <GoalDetail />
        {props.children}
      </Theme>
    </Provider>
  )
}

export default Shell
