import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useGlobalStateContext } from "../../utilities/context"
import Flex from "./flex"
import Icon from "./icon"

const Box = styled.div`
  border-radius: 100%;
  width: 5rem;
  height: 5rem;
  font-size: 0.625rem;
  position: absolute;
  padding: 1rem;
  text-align: center;
  z-index: 10;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--c-blue-200);
  display: none;
  pointer-events: none;
  transition: transform 0.1s;

  > div {
    width: 100%;
    height: 100%;
  }

  &.watch {
    display: block;
  }
`

const Ele = () => {
  const { cursor } = useGlobalStateContext()
  const [pos, setPos] = useState({ x: 0, y: 0 })

  const handleMouseMove = event => {
    const { pageX: x, pageY: y } = event
    setPos({ x, y })
  }
  useEffect(() => {
    if (cursor !== "") {
      document.addEventListener("mousemove", handleMouseMove)
    }
    return () => {
      document.removeEventListener("mousemove", handleMouseMove)
    }
  }, [cursor])

  return (
    <Box
      className={cursor}
      style={{
        transform:
          "translate3d(" + (pos.x - 40) + "px, " + (pos.y - 40) + "px, 0)",
      }}
    >
      <Flex>
        <Icon title="play" />
      </Flex>
    </Box>
  )
}
export default Ele
