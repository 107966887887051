import React from "react"
import { ThemeProvider } from "styled-components"

const mint = {
  mint: "#00e2c1",
  mint500: "#a3f5e8",
  mint600: "#009572",
  black: "#020812",
  darkblue: "#12171f",
}

const Theme = ({ children }) => {
  return <ThemeProvider theme={mint}>{children}</ThemeProvider>
}

export default Theme
