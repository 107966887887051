import React from "react"
import styled, { css } from "styled-components"

const Box = styled.div`
  display: flex;

  ${props =>
    props.edges &&
    css`
      justify-content: space-between;
    `}

  ${props =>
    props.end &&
    css`
      justify-content: flex-end;
    `}

  ${props =>
    props.center &&
    css`
      align-items: center;
    `}

    ${props =>
    props.middle &&
    css`
      justify-content: center;
    `}
`

const Ele = ({ children, edges, center , middle}) => {
  return <Box edges={edges} center={center} middle={middle}>{children}</Box>
}
export default Ele
