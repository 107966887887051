import React from "react"
import styled from "styled-components"
import ConvergeBlue from "../../images/xa_converge-blue.svg"
import ConvergeLime from "../../images/xa_converge-lime.svg"
import DotBlue from "../../images/xa_dot-blue.svg"

const Box = styled.div`
  background-image: url("${ConvergeBlue}");
  background-repeat: repeat;
  background-position: center;

  &.size-4 {
    background-size: 0.25rem;
  }

  &.strip {
    width: 1rem;
    height: 100%;
    background-image: url("${ConvergeLime}");
    background-color: ${props => props.theme.black};
    background-repeat: repeat-y;
    background-size: 1.25rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &.dot {
    background-image: url("${DotBlue}");
  }

  &.green {
    background-image: url("${ConvergeLime}");
  }
`

const Pattern = ({ size, type }) => {
  return <Box className={"xn-pattern size-" + size + " " + type}></Box>
}

export default Pattern
