import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { rem } from "../../utilities/pxtorem"
import Icon from "./icon"

const Box = styled.div`
  border-radius: 2px;
  background-color: var(--c-mint-100);
  color: ${props => props.theme.black};
  display: inline-block;
  height: 2.75rem;

  &.outline {
    border: solid 1px var(--c-mint-100);
    background: transparent;

    svg {
      stroke: #fff;
    }
  }

  button,
  a {
    padding: 0 1.125rem;
    text-transform: uppercase;
    font-size: ${rem(12)};
    height: 100%;
    display: inline-flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    &:hover {
      svg {
        transform: translate3d(0.25rem, 0, 0);
      }
      &:after {
        opacity: 0.35;
        transform: none;
      }
    }
  }

  span,
  svg {
    position: relative;
    z-index: 1;
  }

  span {
    font-weight: bold;
    margin-right: 0.5rem;
    flex-shrink: 0;
    transition: color 0.3s;
  }

  svg {
    width: 0.7rem;
    height: 0.7rem;
    position: relative;
    margin-right: 0.125rem;
    transition: transform 0.6s cubic-bezier(0.22, 1, 0.36, 1);

    path {
      transition: fill 0.3s;
    }
  }

  /* dark */
  ${props =>
    props.dark &&
    css`
      background-color: var(--c-blue-200);
      button,
      a {
        color: var(--c-white);
      }

      path {
        fill: var(--c-white);
      }
    `}

  /* pill */
  ${props =>
    props.pill &&
    css`
      background-color: var(--c-blue-200);
      border-radius: 5rem;
      height: 2rem;

      button,
      a {
        padding: 0 1rem;
      }
    `}
`

const Button = ({ label, to, theme, click, pill, dark, id }) => {
  const handleClick = () => {
    click()
  }
  return (
    <Box className={theme} pill={pill} dark={dark}>
      {!to && (
        <button
          id={id}
          onClick={() => {
            handleClick()
          }}
        >
          <span>{label}</span>
          {/* <Icon title="chevron-black" /> */}
        </button>
      )}

      {to && to.substring(0, 4) !== "http" && (
        <Link to={to} id={id}>
          <span>{label}</span>
          {/* <Icon title="chevron-black" /> */}
        </Link>
      )}

      {to && to.substring(0, 4) === "http" && (
        <a href={to} rel="noreferrer" title={label} id={id}>
          <span>{label}</span>
          {/* <Icon title="chevron-black" /> */}
        </a>
      )}
    </Box>
  )
}

export default Button
