import React, { useEffect, useState } from "react"
import styled, { keyframes } from "styled-components"
import {
  useGlobalDispatchContext,
  useGlobalStateContext,
} from "../../utilities/context"
import Icon from "./icon"

const scaleIn = keyframes`
  0% {
    transform: scale3d(0.95,0.95,0.95);
    opacity: 0; 
  }

  100%{
    transform: none; 
    opacity: 1; 
  }
`

const Box = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  top: 0;
  left: 0;
  z-index: 1000;
  display: none;
  align-items: center;
  justify-content: center;

  &.visible {
    display: flex;
  }
`

const Media = styled.div`
  width: 75%;
  position: relative;
  animation: ${scaleIn} 0.75s ease both;

  > div {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    background: gainsboro;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

const CloseBtn = styled.button`
  position: absolute;
  left: -2.25rem;
  top: -2.25rem;
  z-index: 2;

  svg {
    width: 1.35rem;
    height: 1.35rem;
    object-fit: contain;
  }
`

const Ele = () => {
  const { video } = useGlobalStateContext()
  const [show, setShow] = useState()
  const dispatch = useGlobalDispatchContext()

  const closePlayer = () => {
    dispatch({
      type: "SET_VIDEO",
      src: "",
    })
  }

  useEffect(() => {
    video ? setShow("visible") : setShow("")
  }, [video])

  return (
    <Box className={show}>
      <Media>
        <CloseBtn onClick={() => closePlayer()}>
          <Icon title="close" fill="#fff" />
        </CloseBtn>
        <div>
          <iframe
            src={video}
            title="XENO Investment"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Media>
    </Box>
  )
}
export default Ele
